import React, { useState} from 'react';
import './Navbar.css'
import { Link as ScrollLink, animateScroll as scroll, scroller} from "react-scroll";
import { Link, useLocation } from "react-router-dom";

import Hamburger from 'hamburger-react'



function Navbar() {
    let location = useLocation();
    const isHomePage = location.pathname === '/';
    const scrollDuration = 500;
    const scrollOffset = -70;
    const [click, setMenuIsOpened] = useState(false);
    const closeMobileMenu = () => {
        setMenuIsOpened(false);
    };
    const scrollToTop = () => {
        scroll.scrollToTop();
    };
    const handleLogoClick = () => {
        closeMobileMenu();
        scrollToTop();
    };
    const handleHomePageNav = (navTo) => {
        return () => {
            closeMobileMenu();
            if (isHomePage) return;

            setTimeout(() => {
                scroller.scrollTo(navTo, {
                    duration: scrollDuration,
                    smooth: true,
                    offset: scrollOffset
                });
            }, 50);
        }
    };
    const handleNonHomePageNav = () => {
        closeMobileMenu();
        scrollToTop();
    };

  return (
    <div>
        <nav className="navbar">
            <div className="navbar-container">
                {
                    isHomePage
                        ?  <div className="navbar-logo" onClick={handleLogoClick}>
                                <h2>ZT</h2>
                            </div>
                        :   <Link
                                to="/"
                                className="navbar-logo"
                                onClick={handleLogoClick}
                            >
                                <h2>ZT</h2>
                            </Link>
                }
                <div className="menu-icon">
                    <Hamburger rounded toggled={click} toggle={setMenuIsOpened}/>
                </div>
                <ul className={click ? 'nav-menu-active' : 'nav-menu'}>

                    <li className="nav-item">
                        {
                            isHomePage
                            ?   <ScrollLink
                                    activeClass="active"
                                    to="functions"
                                    spy={true}
                                    smooth={true}
                                    offset={scrollOffset}
                                    duration={scrollDuration}
                                    className="nav-links menu-item"
                                    onClick={closeMobileMenu}>
                                    Expertise
                                </ScrollLink>
                            :
                                <Link
                                to="/"
                                className="nav-links menu-item"
                                onClick={handleHomePageNav('functions')}>
                                    Expertise
                                </Link>
                        }
                    </li>

                    <li className="nav-item">
                        {
                            isHomePage
                            ?   <ScrollLink
                                    activeClass="active"
                                    to="about"
                                    spy={true}
                                    smooth={true}
                                    offset={scrollOffset}
                                    duration={scrollDuration}
                                    className="nav-links menu-item"
                                    onClick={closeMobileMenu}
                                >
                                    About
                                </ScrollLink>
                            :
                                <Link
                                    to="/"
                                    className="nav-links menu-item"
                                    onClick={handleHomePageNav('about')}
                                >
                                    About
                                </Link>
                        }
                    </li>

                    <li className="nav-item">
                        <Link
                        to="projects"
                        className="nav-links menu-item"
                        onClick={handleNonHomePageNav}>
                            Projects
                        </Link>
                    </li>


                    {/* <li className="nav-item">
                        <Link
                        to="cv"
                        className="nav-links menu-item"
                        onClick={handleNonHomePageNav}>
                            CV
                        </Link>
                    </li> */}
                </ul>
            </div>
        </nav>
    </div>
  )
}

export default Navbar