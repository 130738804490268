import React from 'react'
import './Functions.css'
import png1 from '../images/circular-economy.png'
import png2 from '../images/research.png'
import png3 from '../images/online-learning.png'
// import png4 from '../images/agronomy.png'


function Functions() {
  return (
    <div className="functions">
        <div className="functions-container">
            {/* <div className="functions-wrapper"> */}
                <ul className="functions-item">
                    <div className='functions-wrapper'>
                        <div className="functions-item-pic-wrap">
                        <img src={png1} alt="Economy" className="functions-item-img"/>
                        </div>
                        <div className="functions-item-info">
                        Food and economic security
                        </div>
                    </div>
                    <div className='functions-wrapper'>
                        <div className="functions-item-pic-wrap">
                        <img src={png2} alt="Monitoring" className="functions-item-img"/>

                        </div>
                        <div className="functions-item-info">
                        Monitoring and evaluation
                        </div>
                    </div>
                    <div className='functions-wrapper'>
                        <div className="functions-item-pic-wrap">
                        <img src={png3} alt="Training" className="functions-item-img"/>

                        </div>
                        <div className="functions-item-info">
                        Training and capacity development
                        </div>
                    </div>
                    {/* <div className='functions-wrapper'>
                        <div className="functions-item-pic-wrap">
                        <img src={png4} alt="Agriculture" className="functions-item-img"/>

                        </div>
                        <div className="functions-item-info">
                        Agriculture
                        </div>
                    </div> */}
                </ul>
            {/* </div> */}
        </div>
    </div>
  )
}

export default Functions