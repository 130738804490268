import React from 'react'
import '../components/Projects.css'
import projectsImage from '../images/projects.jpg'
import projectsPic1 from '../images/IMGP4625.JPG'
import projectsPic2 from '../images/IMGP2256.JPG'
import projectsPic3 from '../images/IMGP3777.JPG'
import projectsPic4 from '../images/IMGP3863.JPG'
import ukAid from '../images/icons/UKaid.png'
import theworldbank from '../images/icons/theworldbank.png'
import faoun from '../images/icons/faoun.png'
import wfp from '../images/icons/wfp.png'
import ifad from '../images/icons/ifad.png'
import bandmg from '../images/icons/bandmg.png'
import ba from '../images/icons/ba.png'

import Footer from '../components/Footer'
import ProjectCard from './ProjectCard'

function Projects() {
  return (
    <div id="projects" className='projects-container'>
      <div className='projects-hero'>
        <div className='projects-image'>
          <img src={projectsImage} alt="ProjectsImg"/>
        </div>
        <div className='projects-title'>
          <h2>Projects</h2>
        </div>
      </div>
      
      <div className="projects-wrapper">
        <p className='projects-wrapper-title'>A selection of projects that I worked on...</p>
        <div className="projects-cards">
          <ProjectCard  
            imgs={[
              {
                src: projectsPic2,
                alt: 'A group of people are smiling somewhere in Africa.'
              },
              {
                src: projectsPic4,
                alt: 'Cloud'
              },
              {
                src: projectsPic3,
                alt: 'House'
              },
              {
                src: projectsPic1,
                alt: 'Green bananas on a banana tree'
              }
            ]}
            details={[
              {
                logoSrc: ukAid,
                title: 'Sustainable Agricultural Intensification Research and Learning in Africa (SAIRLA)',
                role: 'Principal Investigator',
                description: 'SAIRLA was a five-year programme which aimed to facilitate multi-scale learning to understand different ways of achieving sustainable agricultural intensification and their developmental implications. The project distributed high quality seeds to selected farmers in Ethiopia and Malawi and researched over the course of four years the various risk management strategies employed by recipient smallholders. The project in Malawi designed experimental games to investigate the intra-household distribution and the socio-economic impact of increased income of women farmers from selling high-value crops supported by the project. Over its course of implementation the project monitored progress and evaluated its impact on livelihoods in the intervention areas.'
              },
              {
                logoSrc: ukAid,
                title: 'Evaluation of “Northern Uganda: Transforming the Economy through Climate Smart Agribusiness” (NU-TEC) project',
                role: 'Deputy Team Leader and Project Manager',
                description: 'The NU-TEC project invested £48 million over seven years to generate systemic change in the agriculture sector in Northern Uganda by boosting investment and by supporting women farmers and the most vulnerable socio-economic groups in Northern Uganda. Oxford Policy Management provided services to monitor and evaluate the socio-economic impacts of this project.'
              },
              {
                logoSrc: ukAid,
                title: 'Evaluation of the UNDP Zimbabwe Resilience Building Fund (ZRBF) Programme',
                role: 'Project Director',
                description: 'The purpose of this evaluation was to assess the current status of key livelihood indicators and understand what works in resilience programming in Zimbabwe, how and why. The ZRBF has been one of UNDP\'s key investments in resilience in Zimbabwe that explicitly aimed to increase the role of women in agriculture.'
              },
              {
                logoSrc: theworldbank,
                title: 'Impact of food price increase on livelihoods in Malawi',
                role: 'Consultant',
                description: 'This project investigated the livelihood impacts of sustained food price increase in Malawi on vulnerable socio-economic groups including women and children.'
              },
              {
                logoSrc: theworldbank,
                title: 'Adaptive Human Development Service Delivery in Ethiopia',
                role: 'Research Lead',
                description: 'The World Bank\'s Human Development (HD) family has been supporting a program called “HD adaptive service delivery” to better understand service  delivery approaches in contexts of crisis. The project identified synergies between various ongoing World Bank projects by investigating their flexibility and adaptiveness to different scenarios.'
              },
              {
                logoSrc: faoun,
                title: 'Policies for Good Economic Management of Sustained Price Increases in African Countries',
                role: 'Consultant',
                description: 'This project aimed to train local development practitioners, including senior ministry officials, on social safety nets, agricultural policies and input subsidies in selected countries in sub-Saharan Africa. Two policy training monographs were authored on these subjects and were presented in Uganda, Kenya, Zambia and Malawi.'
              },
              {
                logoSrc: wfp,
                title: 'Developing a Food Security Monitoring Plan for Namibia',
                role: 'Lead Consultant',
                description: 'This project developed a food security monitoring system in Namibia, led by the World Food Programme and the Office of the Prime Minister. The collected set of indicators provide real-time information about the food and nutrition security situation in the different parts of the country and aim to trigger timely response in case of deteriorating conditions. The research reviewed the existing monitoring systems in Namibia.'
              },
              {
                logoSrc: ifad,
                title: 'Building Resilience in Ethiopia: Rural Poor Stimulus Facility',
                role: 'Lead consultant',
                description: 'This project investigated the impact of COVID-19 on food security in rural Ethiopia and designed various intervention modalities to mitigate its negative impact on vulnerable social groups - including women and children. The paper which was subsequently used by the Ministry of Finance in Ethiopia to apply for an IFAD grant (Rural Poor Stimulus Facility) worth US$ 5 million that aims to mitigate the impact of COVID-19 in the rural areas.'
              },

              {
                logoSrc: bandmg,
                title: 'Economic growth in India: a role for human development',
                role: 'Lead author of the agriculture chapter',
                description: 'This project aimed to identify and establish synergies between the Indian government\'s priority areas to stimulate growth and BMGF\'s sectoral interests and investment priorities. The chapter on agriculture was one of the main chapters of the report with special emphasis on women\'s role in agricultural production.'
              },
              {
                logoSrc: bandmg,
                title: 'A survey of the evidence on human capital contributions to economic growth in Nigeria (White Paper for BMGF Nigeria)',
                role: 'Lead author of the agriculture chapter',
                description: 'This study looked at three sectors (health, agriculture, and financial inclusion) to consider their potential contributions to Nigeria\'s future growth trajectory. The report highlighted the potential impact of women on socio-economic development in Nigeria.'
              },
              {
                logoSrc: ba,
                title: 'Agricultural and Food Policies in Sub-Saharan Africa (Postdoctoral Research)',
                role: 'Principal Investigator',
                description: 'Applied research was conducted for this project over the course of three years on agricultural and food policies and their outcomes in Sub-Saharan Africa. Primary data were collected in rural Malawi on livelihood options of the most vulnerable social groups.'
              },
            ]}
          />
        </div>
      </div>
      <Footer id ="footer"/>
    </div>
    
  )
}

export default Projects