import React from 'react'
import './About.css'
import aboutWeb from '../images/profile_pic.jpg'
import aboutPic1 from '../images/IMGP2276.JPG'
import aboutPic2 from '../images/IMGP2216.JPG'
import aboutPic3 from '../images/IMGP4573.JPG'
import aboutPic4 from '../images/countries.jpg'
import Card from './Card';

function About() {
  return (
    <div className="about">
        <div className="about__container">
            <h2>ABOUT ME</h2>
                <div className="about__wrapper">
                    <div className='about__item__main'>
                        <div className="about__item__main-pic-wrap">
                            <img src={aboutWeb} alt="About" className="about__item__img__web"/>
                        </div>
                        <div className="about__item__main-info">
                            <div className="about__item__main-text"><span>Overview</span><br/>
                            I am a development economist with broad interests in food and agricultural policies and practices in developing countries.  Over the past two decades I have had a varied career in development, research, training and project design and combined theoretical work with applied field research on food security, agriculture and the socio-economics of rural livelihoods in East and sub-Saharan Africa and in South-East Asia.
                            </div>
                        </div>
                    </div>

                    <div className="card-container">
                        <Card
                            imgSrc={aboutPic1}
                            imgAlt="About"
                            title="Education"
                            details={[
                                'PhD Economics (SOAS, 2005)',
                                'PhD International Relations (Budapest Corvinus University, 2006)',
                                'British Academy Postdoctoral fellow (University of Cambridge, 2007-2010)'
                            ]}
                        />

                        <Card
                            imgSrc={aboutPic2}
                            imgAlt="About"
                            title="Professional work experience"
                            details={[
                                'World Food Programme (Rome) - Policy Advisor',
                                'Food and Agriculture Organization (Nairobi) - Research and Capacity Development',
                                'Manager Oxford Policy Management (Oxford) - Senior Consultant',
                                'Institute for World Economics (Budapest) - Senior Research Fellow'
                            ]}
                        />

                        <Card
                            imgSrc={aboutPic4}
                            imgAlt="About"
                            title="Countries of work experience"
                            details={[
                                'Armenia',
                                'Bhutan',
                                'Ethiopia',
                                'Kenya',
                                'Malawi',
                                'Namibia',
                                'Nicaragua',
                                'Russia',
                                'Somalia',
                                'Uganda',
                                'Vietnam',
                                'Zambia',
                                'Zimbabwe',
                            ]}
                        />

                        <Card
                            imgSrc={aboutPic3}
                            imgAlt="About"
                            title="Areas of expertise"
                            details={[
                                {
                                    category: 'Food and economic security',
                                    examples: [
                                        'Livelihood & resilience analysis',
                                        'Early warning and intervention design',
                                        'Food aid and nutrition'
                                    ]
                                },
                                {
                                    category: 'Monitoring and evaluation',
                                },
                                {
                                    category: 'Training and capacity development'
                                },
                                {
                                    category: 'Agriculture'
                                },
                            ]}
                        />

                    </div>

                    {/* <ul className="about__items">
                        
                        
                        <li className='about__item__1'>
                            


                            <div className="about__item__pic-wrap">
                                <img src={aboutPic1} alt="About" className="about__item__img__web"/>
                            </div>

                            <div className="about__item__info">
                                <div className="about__item__text"><span>Education</span><br/>
                                    <ul>
                                        <li>PhD Economics (SOAS, 2005)</li>
                                        <li>PhD International Relations (Budapest Corvinus University, 2006)</li>
                                        <li></li>
                                    </ul>
                                </div>
                                </div>
                        </li>
                                <li className='about__item__1'>
                            <div className="about__item__pic-wrap">
                                <img src={aboutPic2} alt="About" className="about__item__img__web"/>
                            </div>
                            <div>
                            <div>
                                <span>Professional work experience</span><br/>
                                World Food Programme (Rome) - Policy Advisor<br/>
                                Food and Agriculture Organization (Nairobi) - Research and Capacity Development Manager Oxford Policy Management (Oxford) - Senior Consultant<br/>
                                Institute for World Economics (Budapest) - Senior Research Fellow.
                                </div>
                            </div>
                        </li>

                        <li className='about__item__1'>
                            <div className="about__item__pic-wrap">
                                <img src={aboutPic3} alt="About" className="about__item__img__web"/>
                            </div>
                            <div className="about__item__info">
                                <div className="about__item__text"><span>Areas of expertise</span><br/>
                                <p>Food and economic security</p>
                                    Livelihood & resilience analysis
                                    Early warning and intervention design
                                    Food aid and nutrition.
                                    <p>Monitoring and evaluation</p>
                                    Ide még írnom kell kicsit...
                                    <p>Training stakeholders and teaching at university level</p>
                                    <p>Agriculture</p>
                                </div>
                            </div>
                        </li>
                    </ul> */}
                </div>
            </div>
            
        </div>
  )
}

export default About