import React from 'react'
import heroImage from '../images/hero.jpg'
import '../components/Hero.css'



function Hero() {
  return (
    <div id="hero" className='hero-container'>
      <div className='hero-image'>
        <img src={heroImage} alt="HeroImg"/>
      </div>
      <div className='hero-title'>
        <h2>ZOLTAN TIBA</h2>
        {/* <p>quote Lorem ipsum quote</p> */}
      </div>
    </div>
  )
}

export default Hero