import React from 'react';
import './Card.css';

function Card({ imgSrc, imgAlt, title, details }) {
    return (
        <div className="card">
            <img src={imgSrc} alt={imgAlt} className="card-img"/>

            <div className="about__item__info">
                <div>
                    <span className="card-title">{title}</span><br/>

                    <ul className="details-list">
                        {
                            details.map((d, i) =>
                                <li key={i} className="details-list__item">
                                    {
                                    typeof d === 'string'
                                        ? d
                                        : (<>
                                            <div>{d.category}</div>
                                            {
                                                <ul className="details-list">
                                                    {d.examples && d.examples.map((ex, j) =>
                                                        <li className="details-list__item" key={j}>{ex}</li>
                                                    )}
                                                </ul>
                                            }
                                        </>)
                                }
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Card;