import React from 'react';
import './ProjectCard.css';

function ProjectCard({ imgs, details }) {
    let numOfDetailsPerImg = 3
    return (
        <div className="project-card">

            <div className="project-item-img">
                {
                    imgs.map((img, idx) => <img className="deco-img" key={idx} src={img.src} alt={img.alt}/>)
                }
            </div>

            <div className="project-item-info">
                {
                    details.map((detail, idx) => {
                        const img = imgs[Math.floor(idx / numOfDetailsPerImg)];
                        return <div key={idx}>
                            <div className="project-header">
                                <div>
                                    <h3 className="project-item-title">
                                        {detail.title}
                                    </h3>
                                    <b className="project-item-role">{detail.role}</b>
                                </div>
                                <div className="logo-container">
                                    <img className="project-logo" src={detail.logoSrc} alt="logo"/>
                                </div>
                            </div>
                            <p className="project-item-description">
                                {detail.description}
                            </p>
                            {
                                !(idx % numOfDetailsPerImg) && img
                                    && <img className="deco-img" src={img.src} alt={img.alt}/>
                            }
                        </div>
                    })
                }
            </div>
        </div>);
}

export default ProjectCard;