import React from 'react'
import '../App.css'
import Hero from '../components/Hero'
import Functions from '../components/Functions'
import About from '../components/About'
import Footer from '../components/Footer'

function Home() {
  return (
    <>
    <div id="home">
        <Hero id="hero" />
        <Functions id="functions"/>
        <About id="about"/>
        <Footer id ="footer"/>
    </div>
  </>
  )
}

export default Home
