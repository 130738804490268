import React from 'react'
import '../components/Footer.css'
import mailIcon from '../images/email.png'
// import linkedinIcon from '../images/linkedin.png'
// import cvIcon from '../images/document.png'

function Footer() {
  return (
    <div className='footer'>
        <div className='footer-container'>
            <div className='footer-title'>
                <h2>CONTACT ME</h2>
            </div>
            <div className='footer-contact-details'>
                <div className='social-media-container'>
                    <a href="mailto:&#099;&#111;&#110;&#116;&#097;&#099;&#116;&#064;&#099;&#111;&#110;&#122;&#111;&#108;&#116;&#097;&#110;&#099;&#121;&#046;&#099;&#111;&#109;">
                        <img src={mailIcon} alt='email'/>
                    </a>
                    {/* <img src={linkedinIcon} alt='linkedin'/> */}
                    {/* <img src={cvIcon} alt='linkedin'/> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer